


















import { Vue, Component } from "vue-property-decorator";
import vesselEnquiryOverviewRoutes from "@/router/vesselEnquiryOverview";
import UserModule from "@/store/userModule";
import { getModule } from "vuex-module-decorators";
import vesselEnquiryOverviewRoutesAgency from '../../../../router/vesselEnquiryOverviewAgency';

const userModule = getModule(UserModule);

@Component
export default class Index extends Vue {
  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private get routes() {
    return this.agencyAdminRole ? vesselEnquiryOverviewRoutesAgency : vesselEnquiryOverviewRoutes;
  }
}
